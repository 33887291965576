:root {
  --bg-color: #1E1E1F;
  --card-bg-color: #323234;
  --acc-color: #FF8552;
  --text-primary: lightgray;
  --text-secondary: grey;
  --text-tertiary: rgb(75, 75, 75);
}

body {
  margin: 0;
  background-color: var(--bg-color);
  text-align: center;
  font-family: Lato;
}

.linkText {
  color: var(--acc-color);
  margin: 10px;
}

 .navbar {
  background-color: var(--bg-color);
  margin-top: 2vh;
  margin-bottom: 2vh;
  font-size: large;
}

.nav-link {
  padding: 0;
}

.container {
  background-color: var(--bg-color);
  width: 50vw;
  min-width: 20rem;
  margin-top: 2vh;
  margin-bottom: 2vh;
}

.col {
  padding: 0
}

.left-col {
  padding-right: 0.5rem;
}

.center-col {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.right-col {
  padding-left: 0.5rem;
}

.row {
  align-items: center;
  text-align: center;
}

.landing-header {
  color: var(--acc-color);
  font-size: 2em;
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

.landing-intro {
  color: var(--text-primary);
  font-size: 1.2em;
  margin-bottom: 1rem;
}

.landing-career {
  color: var(--text-primary);
  background-color: var(--card-bg-color);
  margin-bottom: 1rem;
  text-align: left;
  /* width: -moz-available;
  width: -webkit-fill-available; */
  width: 100%;
}

.landing-card {
  color: var(--text-primary);
  background-color: var(--card-bg-color);
  margin-bottom: 1rem;
  text-align: left;
}

.landing-card a {
  color: var(--acc-color);
}

.landing-career a {
  color: var(--acc-color);
}

.icon-card {
  color: var(--acc-color);
  text-align: center;
  font-size: .9rem;
}

.services-card {
  text-align: center;
  align-self: center;
  width: 100%
}

.not-found-card {
  color: var(--text-primary);
  background-color: var(--card-bg-color);
  margin-top: 20%;
  margin-bottom: 1rem;
  text-align: center;
  width: 100%;
}

.not-found-card a {
  color: var(--acc-color);
}

.table {
  width: 50%;
  align-self: center;
}

.table thead {
  color: var(--text-secondary);
}

.table tbody {
  color: var(--text-primary);
}

.table tr, .table td  {
  padding: 5px;
  margin: 0;
  text-align: center;
  border-color: var(--text-tertiary);
}

.table th, .table thead th {
  padding: 0.5rem;
  padding-top: 0;
  margin: 0;
  text-align: center; 
}

.table-responsive-true {
  align-self: center;
  width: 100%;
  display: grid;
}

.bio-pic {
  max-width: 20%;
  max-height: 20%;
  margin-bottom: 1rem;
}